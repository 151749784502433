@import url(https://fonts.googleapis.com/css2?family=Catamaran:wght@400;600&display=swap);
* {
  font-family: "Catamaran", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

body {
  padding: 0;
  margin: 0;
  font-family: "Josefin Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    font-family: "Josefin Sans";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.main-header {
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
}

.content {
  margin-top: 3.5rem;
  padding: 1rem;
}

@media (min-width: 768px) {
  .content {
    margin-top: 3.5rem;
    padding: 1rem 2rem;
  }
}

.backdrop {
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.75);
  z-index: 100;
  position: fixed;
  left: 0;
  top: 0;
  transition: opacity 0.3s ease-out;
  opacity: 1;
}

.toolbar {
    width: 100%;
    height: 3.5rem;
    background: #3b0062;
}
.mobile-toggle {
  background: transparent;
  border: none;
  display: flex;
  width: 2.5rem;
  height: 80%;
  flex-direction: column;
  justify-content: space-evenly;
  cursor: pointer;
  padding: 0;
  margin-right: 1rem;
}

.mobile-toggle__bar {
  width: 2.5rem;
  height: 4px;
  background: white;
}

@media (min-width: 768px) {
    .mobile-toggle {
        display: none;
    }
}
.logo {
    font-size: 1rem;
    font-weight: bold;
    border: 1px solid white;
    padding: 0.5rem;
    color: white;
}
.navigation-item {
  padding: 0 1.5rem;
}

.navigation-item a {
  text-decoration: none;
  color: white;
}

.navigation-item.mobile {
  font-size: 1.75rem;
  margin: 0.5rem 0;
}

.navigation-item.mobile a {
  color: #3b0062;
}

.navigation-item a:hover,
.navigation-item a:active,
.navigation-item a.active {
  color: #fab83f;
}

.navigation-item:last-of-type {
  padding-right: 0;
}

.navigation-item button {
  font: inherit;
  background: transparent;
  border: none;
  color: white;
  cursor: pointer;
  padding: 0;
}

.navigation-item button:hover,
.navigation-item button:active {
  color: #fab83f;
}

.main-nav {
  height: 100%;
  padding: 0 1rem;
  display: flex;
  align-items: center;
}

.spacer {
  flex: 1 1;
}

.main-nav__items {
  list-style: none;
  padding: 0;
  margin: 0 1.5rem;
  display: none;
}

@media (min-width: 768px) {
  .main-nav__items {
    display: flex;
  }
}

.mobile-nav {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 30rem;
  max-width: 90%;
  background: white;
  box-shadow: 1px 0 8px rbga(0, 0, 0, 0.26);
  transition: transform 0.3s ease-out;
  transform: translateX(-100%);
  z-index: 200;
  padding: 3rem 2rem;
}

.mobile-nav.open {
  transform: translateX(0);
}

.mobile-nav__items {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
}

.mobile-nav__items.mobile {
    flex-direction: column;
}

.button {
  font: inherit;
  border: 1px solid #3b0062;
  color: #3b0062;
  background: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
  text-transform: uppercase;
  text-decoration: none;
}

.button:focus {
  outline: none;
}

.button:disabled {
  background: #ccc;
  color: #888888;
  cursor: not-allowed;
  border: #ccc;
  box-shadow: none;
}

.button:disabled:hover,
.button:disabled:active {
  background: #ccc;
  color: #888888;
  border: #ccc;
}

.button:hover,
.button:active {
  background: #3b0062;
  color: white;
}

.button--flat {
  border: none;
}

.button--flat:hover,
.button--flat:active {
  background: rgba(59, 0, 98, 0.3);
  color: #3b0062;
}

.button--raised {
  background: #3b0062;
  color: white;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.26);
}

.button--raised:hover,
.button--raised:active {
  background: #520288;
}

.button.button--accent {
  border-color: #fab83f;
  color: #fab83f;
}

.button--accent:hover,
.button--accent:active {
  background: #fab83f;
  color: #3b0062;
}

.button--flat.button--accent:hover,
.button--flat.button--accent:active {
  background: rgba(250, 184, 63, 0.3);
  color: #fab83f;
}

.button.button--accent.button--raised {
  background: #fab83f;
  color: #3b0062;
}

.button--raised.button--accent:hover,
.button--raised.button--accent:active {
  background: #fbc766;
}

.button.button--danger {
  border-color: #a30000;
  color: #a30000;
}

.button--danger:hover,
.button--danger:active {
  background: #a30000;
  color: white;
}

.button--flat.button--danger:hover,
.button--flat.button--danger:active {
  background: rgba(163, 0, 0, 0.3);
  color: #a30000;
}

.button.button--danger.button--raised {
  background: #a30000;
  color: white;
}

.button--raised.button--danger:hover,
.button--raised.button--danger:active {
  background: #c00000;
}

.button.button--success {
  border-color: #00b359;
  color: #00b359;
}

.button--success:hover,
.button--success:active {
  background: #00b359;
  color: white;
}

.button--flat.button--success:hover,
.button--flat.button--success:active {
  background: rgba(0, 179, 90, 0.3);
  color: #00b359;
}

.button.button--success.button--raised {
  background: #00b359;
  color: white;
}

.button--raised.button--success:hover,
.button--raised.button--success:active {
  background: #00a151;
}

.modal {
  position: fixed;
  width: 90%;
  left: 5%;
  top: 20vh;
  background: white;
  border-radius: 5px;
  z-index: 200;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
}

.modal__header {
  border-bottom: 2px solid #3b0062;
}

.modal__header h1 {
  font-size: 1.5rem;
  color: #3b0062;
  margin: 1rem;
}

.modal__content {
  padding: 1rem;
}

.modal__actions {
  padding: 1rem;
  text-align: right;
}

.modal__actions button {
  margin: 0 0.5rem;
}

@media (min-width: 768px) {
  .modal {
    width: 40rem;
    left: calc((100% - 40rem) / 2);
  }
}

.post {
  margin: 1rem 0;
  border: 1px solid #3b0062;
  border-radius: 5px;
  padding: 0.5rem;
}

.post__meta {
  font-size: 1rem;
  color: #707070;
  margin: 0;
}

.post__title {
  font-size: 1.5rem;
  margin: 1rem 0;
  color: #3b0062;
}

.post__image {
  height: 15rem;
  width: 100%;
}

.post__actions {
  text-align: right;
}

@media (min-width: 768px) {
  .post {
    padding: 1rem;
    width: 40rem;
    margin-left: auto;
    margin-right: auto;
  }
}

.input {
  margin: 1rem 0;
  width: 100%;
}

.input label {
  display: block;
  text-transform: uppercase;
  margin-bottom: 0.25rem;
}

.input input,
.input textarea {
  display: block;
  font: inherit;
  padding: 0.25rem 0.5rem;
  width: 100%;
  border-radius: 3px;
  border: 1px solid #ccc;
}

.input .touched.invalid {
    border-color: red;
    background: #ffc2c2;
}

.input input:focus,
.input textarea:focus {
  outline: none;
  border-color: #3b0062;
  color: #3b0062;
}

.image {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
}
.paginator__controls {
  display: flex;
  justify-content: center;
}

.paginator__control {
  width: 5rem;
  padding: 0.25rem 0;
  margin: 0 1rem;
  border: 1px solid #3b0062;
  background: transparent;
  font: inherit;
  cursor: pointer;
  font-size: 1rem;
  color: #3b0062;
}

.paginator__control:hover,
.paginator__control:active {
  color: #fab83f;
  border-color: #fab83f;
}

.paginator__control:focus {
  outline: none;
}

.loader {
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;
  }
  .loader div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 51px;
    height: 51px;
    margin: 6px;
    border: 6px solid #3b0062;
    border-radius: 50%;
    -webkit-animation: loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
            animation: loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #3b0062 transparent transparent transparent;
  }
  .loader div:nth-child(1) {
    -webkit-animation-delay: -0.45s;
            animation-delay: -0.45s;
  }
  .loader div:nth-child(2) {
    -webkit-animation-delay: -0.3s;
            animation-delay: -0.3s;
  }
  .loader div:nth-child(3) {
    -webkit-animation-delay: -0.15s;
            animation-delay: -0.15s;
  }
  @-webkit-keyframes loader {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes loader {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
.feed__status {
  width: 90%;
  margin: 1rem auto;
}

.feed__status form {
  display: flex;
  align-items: center;
}

.feed__status form * {
  margin: 0 0.5rem;
}

.feed__control {
  text-align: center;
}

.new-post__preview-image {
  width: 15rem;
  height: 7rem;
}

@media (min-width: 768px) {
  .feed__status {
    width: 30rem;
  }
}

.single-post {
  width: 90%;
  margin: auto;
  text-align: center;
  color: #3b0062;
}

.single-post h2 {
  font-size: 1rem;
  color: #464646;
  padding-bottom: 1rem;
  border-bottom: 2px solid #464646;
}

.single-post__image {
  height: 20rem;
  width: 20rem;
  margin: 1rem auto;
}

@media (min-width: 768px) {
  .single-post {
    width: 40rem;
  }
}

.auth-form {
  width: 90%;
  margin: auto;
  padding: 1rem;
  border: 1px solid #3b0062;
  border-radius: 5px;
}

.input {
  width: 97%;
}

@media (min-width: 768px) {
  .auth-form {
    width: 40rem;
  }
}

.App {
    text-align: center;
    min-height: 100vh;
    display: flex;
    margin: 75px 0 25px;
    flex-direction: flex-start;
    align-items: flex-start;
    justify-content: center;
    font-size: calc(10px + 2vmin);
  }
  
  .App-container {
    width: 100%;
    margin: 0 auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .note-row p {
    margin: 0 34px 0 0;
    padding: 0;
    outline: none;
    min-width: 250px;
  }
  
  .note-input {
    height: 32px;
    border: none;
    padding: 5px 20px;
    min-width: 250px;
    border-radius: 4.5px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    outline: none;
  }
  
  .notes-container {
    display: flex;
    flex-direction: column;
    align-items: space-between;
  }
  
  .note-row {
    margin: 12px 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-align: left;
  }
  
